.Messages {
    &-Friend {
        &Item {
            margin-bottom: 0.5rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            cursor: pointer;
            padding: 0.5rem;
            border-radius: 4px;

            &--selected {
                background: #f5f3f7; }

            img {
                width: 48px;
                height: 48px;
                margin-right: 10px; }

            &:hover {
                overflow: visible; } } }

    &-Message {
        &Item {
            margin-bottom: 1rem;
            background: #202693;
            color: #fff;
            border-radius: 4px;
            padding: 1rem;
            margin-left: 20%;
            max-width: 80%;

            &--me {
                margin-left: 0;
                background: #f5f3f7;
                color: #111; }

            &__time {
                text-align: right;
                opacity: .8;
                font-size: .8rem; } }

        &sList {
            &__form {
                margin-bottom: 1rem;
                position: relative;
                padding: 0;

                &:after {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: #fff;
                    z-index: -1;
                    opacity: 0;
                    transition: opacity .2s; }

                &--sending {
                    &:after {
                        z-index: 9999;
                        opacity: .5; } } }

            &__textarea {
                display: block;
                width: 100%;
                border: 1px #eee solid;
                border-radius: 4px;
                padding: 1rem;
                height: 4rem;
                margin-bottom: 0.5rem; } } } }

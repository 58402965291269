.App {
    position: relative;
    //padding-bottom: 56.25%
    padding-bottom: 60.25%;
    width: 100%;

    &-absolute {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%; }

    &-inner {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-flow: column;
        border-radius: rem(17);
        background: #151826;
        border: rem(1) solid #4A4C6D; }

    &-logo {
        animation: App-logo-spin infinite 20s linear;
        height: 40vmin; }

    &-container {
        padding: 0 20px; }

    &-loading {
        position: absolute;
        top: 50%;
        left: 50%;
        width: rem(38);
        height: rem(38);
        margin-left: rem(-19);
        margin-top: rem(-19);

        &:before {
            content: "";
            display: block;
            width: rem(38);
            height: rem(38);
            background: url(../../../public/assets/images/loader.png) no-repeat center center / rem(38) rem(38);
            animation: rotation 1s infinite linear; } }

    &-vertical-center {
        justify-content: center;
        align-items: start;
        display: flex;
        height: 100%;
        flex-flow: column; }


    &-button {
        background: linear-gradient(91.58deg, #513FF5 1.24%, #FF5E22 65.86%, #FF5E22 97.41%);
        border-radius: rem(10);
        padding: rem(2);
        display: inline-block;
        border: 0;
        position: relative;

        span {
            /*background: #282740*/
            display: inline-block;
            border-radius: rem(8);
            font-weight: 700;
            font-size: rem(16);
            line-height: 120%;
            color: #fff;
            padding: rem(16);
            transition: all .2s;
            position: relative;
            cursor: pointer;
            z-index: 2; }

        &:after {
            content: "";
            position: absolute;
            top: rem(2);
            left: rem(2);
            width: calc(100% - 0.25rem);
            height: calc(100% - 0.25rem);
            border-radius: rem(8);
            opacity: 0;
            transition: opacity .2s;
            background: linear-gradient(259.55deg, rgba(255, 94, 34, 0.04) 0.72%, rgba(81, 63, 245, 0.24) 98.18%); }


        &:before {
            content: "";
            position: absolute;
            top: rem(2);
            left: rem(2);
            width: calc(100% - 0.25rem);
            height: calc(100% - 0.25rem);
            border-radius: rem(8);
            opacity: 1;
            transition: opacity .2s;
            background: #282740; }

        &.w-100 span {
            width: 100%; }

        &.p-40 span {
            padding-left: rem(40);
            padding-right: rem(40); }

        &:focus {
            outline: rem(2) solid rgba(255, 255, 255, 0.6);
            outline-offset: rem(3); }

        &:hover {
            &:after {
                opacity: 1; } }



        &-secondary {
            background: #444362;

            &:after {
                background: linear-gradient(219.67deg, rgba(52, 50, 79, 0) 15.61%, #34324F 179.87%); }

            &:hover {
                &:after {
                    background: radial-gradient(92.97% 104.63% at 50% 50%, rgba(49, 47, 78, 0) 0%, #3C3A5B 100%); } } }

        &--tw {
            span {
                font-size: rem(13);
                font-style: normal;
                font-weight: 400;
                line-height: 120%;
                padding: rem(11) rem(30);

                strong {
                    font-size: rem(16);
                    font-style: normal;
                    font-weight: 700;
                    line-height: 120%; } } } }


    &-profile {
        &-avatar {
            margin-right: rem(28);
            border-radius: 100%;
            width: rem(146);
            height: rem(146); }

        &-info {
            margin-bottom: rem(28); }

        &-trans {
            &-buttons {
                .button {
                    margin-right: rem(8); } } }



        &-cloud {
            float: right;
            color: #fff;
            text-align: right;

            &__plus {
                display: inline-block;
                margin-top: rem(20);
                font-weight: 700;
                font-size: rem(16);
                line-height: 120%;
                color: #fff;
                padding-left: rem(36);
                background: url(../../../public/assets/images/icon/add.svg) no-repeat left center / rem(24) rem(24);
                text-decoration: none;
                cursor: pointer;

                &.is-disable {
                    opacity: .3;
                    cursor: not-allowed; } }

            &__bar {
                white-space: nowrap;
                margin-top: rem(6);
                font-size: 0;

                &__dots {
                    > span {
                        display: inline-block;
                        width: rem(15);
                        height: rem(15);
                        margin-left: rem(4);
                        background: radial-gradient(327.39% 184.12% at 89.3% -2.17%, #444362 0%, #444362 100%);
                        border-radius: rem(4);
                        position: relative;
                        overflow: hidden;

                        > span {
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 0%;
                            height: 100%;
                            background: #26E974; } } } } } }


    &-top {
        padding-top: rem(40); }

    &-inventory {
        &-title {
            font-weight: 400;
            font-size: rem(16);
            line-height: 120%;
            color: rgba(255, 255, 255, 0.6);
            margin-bottom: rem(26);

            strong {
                color: #ffffff;
                font-weight: 700;
                margin-left: rem(8); } }

        &-breadcrumb {
            font-weight: 800;
            font-size: rem(24);
            line-height: rem(40);
            letter-spacing: rem(1);
            color: #FFFFFF;
            margin-bottom: rem(52);

            a {
                color: #ffffff;
                text-decoration: none;
                position: relative;
                margin-right: rem(2);
                padding-right: rem(26);

                &:last-child {
                    padding-right: 0;
                    margin-right: 0;

                    &:after {
                        display: none; } }

                &:after {
                    content: "";
                    position: absolute;
                    right: 0;
                    top: rem(5);
                    width: rem(24);
                    height: rem(24);
                    background: url(../../../public/assets/images/icon/expand_more.svg) no-repeat top left / rem(24) rem(24); }

                &:hover {
                    text-decoration: underline; } } }

        &-row {
            display: flex;
            flex-wrap: wrap;
            margin: 0 rem(-15);

            > * {
                flex: 0 0 calc(100% / 7);
                max-width: calc(100% / 7);
                padding: 0 15px rem(23); }

            &-folder {
                display: flex;
                flex-wrap: wrap;
                margin: 0 rem(-15);

                > * {
                    flex: 0 0 25%;
                    max-width: 25%;
                    padding: 0 15px rem(23); } } }

        &-box {
            background: linear-gradient(90.85deg, #1D1E34 8.8%, #181A33 105.31%);
            border-radius: rem(10);
            height: 100%;
            padding: rem(9) rem(19) rem(25) rem(19);
            color: #fff;

            &:hover {
                color: #fff; }

            &-title {
                font-style: normal;
                font-weight: 400;
                font-size: rem(12);
                line-height: 120%;
                margin-bottom: 0; }

            &-link {
                font-weight: 400;
                font-size: rem(16);
                line-height: 120%;
                margin-bottom: 0;

                a {
                    color: #fff; } }


            &--mini-ico {
                display: flex;
                flex-flow: column;
                justify-content: space-between;

                h3 {
                    margin-bottom: 0; }

                img {
                    margin-bottom: rem(42); } }

            &--big-ico {
                padding: rem(22);
                text-align: center;
                display: flex;
                flex-flow: column;
                justify-content: space-between;

                h3 {
                    margin-bottom: 0;
                    overflow-wrap: anywhere; }

                img {
                    margin-bottom: rem(10);
                    max-height: rem(100);
                    width: auto;
                    height: auto; } } } }


    &-single-form {
        display: flex;
        align-items: center;

        button {
            margin-left: 24px;
            min-width: 123px; } }

    &-input {
        height: rem(52);
        padding: 0 rem(24);
        width: 100%;
        background: #444362;
        border-radius: rem(10);
        border: rem(2) #444362 solid;
        font-weight: 400;
        font-size: rem(16);
        line-height: 120%;
        color: #fff; }

    &-title {
        font-weight: 800;
        font-size: rem(32);
        line-height: rem(40);
        margin-bottom: rem(20);

        &--smaller {
            font-size: rem(24); }

        &__icons {
            img {
                vertical-align: top;
                margin: 0 0 0 rem(9);
                position: relative;
                top: rem(2);
                width: rem(36);
                height: rem(36); } } }

    &-subtitle {
        font-weight: 800;
        font-size: rem(23);
        line-height: rem(22);
        letter-spacing: rem(1);
        margin-bottom: rem(24);

        small {
            font-weight: 400;
            font-size: rem(18); } }

    &-box {
        background: linear-gradient(90.85deg, #1D1E34 8.8%, #181A33 105.31%);
        border-radius: rem(10);
        padding: rem(30);
        color: #fff;
        margin-bottom: rem(8);
        position: relative;
        z-index: 2;

        &__inner {
            padding: rem(30); }

        &--profile {
            padding-bottom: rem(52); }

        &__buttons {
            margin-top: rem(24);

            .button {
                margin-right: rem(12); } }

        &--z1 {
            z-index: 1; }

        &-planet {
            width: rem(492);
            height: rem(479);
            position: absolute;
            bottom: rem(-51);
            right: rem(-123);
            background: url(../../../public/assets/images/icon/planet.svg) no-repeat top left / contain; } }

    &-stardust {
        &-line {
            margin-top: rem(-13);
            margin-bottom: rem(7);
            position: relative;
            z-index: 2;

            &__left, &__center, &__right {
                position: absolute;
                top: rem(-30); }

            &__left, &__right, &__center {
                font-weight: 400;
                font-size: rem(12);
                line-height: 120%;
                color: rgba(255, 255, 255, 0.6); }

            &__left {
                left: rem(30); }

            &__right {
                right: rem(30); }

            &__center {
                color: #fff;
                left: 50%;
                transform: translateX(-50%); }

            &__content {
                display: flex;
                justify-content: space-between;

                > span {
                    width: calc(100%/9 - 0.375rem);
                    position: relative;
                    border-radius: rem(5);
                    height: rem(10);
                    background: radial-gradient(327.39% 184.12% at 89.3% -2.17%, #444362 0%, #444362 100%);
                    overflow: hidden;

                    > span {
                        position: absolute;
                        top: 0;
                        left: 0;
                        height: 100%; }

                    &:nth-child(1) > span {
                        background: linear-gradient(to right, #513ff5, #7044cf); }

                    &:nth-child(2) > span {
                        background: linear-gradient(to right, #7245cd, #904aa8); }

                    &:nth-child(3) > span {
                        background: linear-gradient(to right, #934aa5, #b05081); }

                    &:nth-child(4) > span {
                        background: linear-gradient(to right, #b25080, #d0555a); }

                    &:nth-child(5) > span {
                        background: linear-gradient(to right, #d25659, #f15c34); }

                    &:nth-child(6) > span {
                        background: linear-gradient(to right, #f35c31, #ff7522); }

                    &:nth-child(7) > span {
                        background: linear-gradient(to right, #ff7722, #ff9c22); }

                    &:nth-child(8) > span {
                        background: linear-gradient(to right, #ff9e22, #ffc522); }

                    &:nth-child(9) > span {
                        background: linear-gradient(to right, #fec921, #ffe922); } } } }
        &-name {
            display: flex;
            align-items: center;

            img {
                margin-right: rem(10);
                width: rem(49);
                height: rem(49); } }

        &-table {
            th {
                font-weight: 400;
                font-size: rem(16);
                line-height: 120%;
                color: rgba(255, 255, 255, 0.6);
                padding-bottom: rem(11); }

            td {
                font-weight: 400;
                font-size: rem(20);
                line-height: rem(22);
                color: #FFFFFF;
                padding-right: rem(50);
                vertical-align: middle;

                &:last-child {
                    padding-right: 0; } } } }

    .row {
        margin: 0 rem(-4);

        > * {
            padding: 0 rem(4); } }

    &-box-light {
        background: #282740; }

    &-login {
        max-width: rem(522);
        width: rem(522);
        margin: 0 auto;

        &__bottom {
            margin-top: 24px;
            font-size: 16px;
            font-style: normal;
            color: rgba(255, 255, 255, 0.60);

            a {
                color: #fff;
                font-weight: 700; } } }

    &-container-header {
        padding: 0 rem(8) rem(8) rem(8); }

    &-header {
        background: linear-gradient(90.85deg, #1D1E34 8.8%, #181A33 105.31%);
        border-radius: rem(10);
        color: #fff;
        margin: 0 auto;
        padding: 0 rem(20) 0 rem(28);

        &:after {
            content: "";
            display: table;
            clear: both; }

        &-time {
            float: left;
            font-weight: 400;
            font-size: rem(16);
            line-height: 120%;
            padding: rem(39) 0;

            &-online {
                display: inline-block;
                padding-left: rem(24);
                color: rgba(255, 255, 255, 0.6);

                span {
                    color: #fff; } } }

        &-logout {
            float: right;

            button {
                background: url(../../../public/assets/images/icon/close.svg) transparent no-repeat center center / rem(24) rem(25);
                border: none;
                width: rem(24);
                height: rem(25);
                color: transparent;
                margin-top: rem(36);
                cursor: pointer; } }

        &-nav {
            margin: 0 auto;
            width: rem(800);
            text-align: center;

            ul {
                width: 100%;
                padding: rem(4) 0;
                margin: 0;
                font-size: 0;
                list-style: none;

                li {
                    padding: 0 rem(12);
                    margin: 0; }

                li, a {
                    display: inline-block; }

                a {
                    font-weight: 400;
                    font-size: rem(16);
                    line-height: 120%;
                    color: rgba(255, 255, 255, 0.6);
                    padding: rem(21) rem(6);
                    position: relative;

                    &:after {
                        content: "";
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        width: 100%;
                        height: rem(2);
                        background: #ffffff;
                        opacity: 0; }

                    img {
                        margin-bottom: rem(3);
                        opacity: .6;
                        width: rem(24);
                        height: rem(24); }

                    &.is-active {
                        color: #fff;

                        img {
                            opacity: 1; }

                        &:after {
                            opacity: 1; } }

                    &:hover {
                        color: #fff;
                        text-decoration: none;

                        img {
                            opacity: 1; } }

                    &.is-disable {
                        opacity: 0.3;
                        cursor: not-allowed;

                        img {
                            opacity: 1; } } } } } }

    &-mobile-rotation {
        display: none;
        z-index: 9;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #151826;
        text-align: center;
        align-items: center;
        justify-content: center;

        img {
            transform-origin: center center;
            margin-bottom: 36px;
            animation: mobile-rotation 1.5s linear infinite;
 }            //animation: mobile-push 1s linear infinite

        p {
            color: #fff;
            font-weight: 400;
            font-size: 16px;
            line-height: 120%; } }

    @media (orientation: portrait) {
        &-mobile-rotation {
            display: flex; } } }


@keyframes mobile-rotation {
    90% {
        transform: rotate(-90deg); }

    100% {
        transform: rotate(0deg); } }

@keyframes mobile-push {
    50% {
        transform: scale(1); }
    95% {
        transform: scale(1.2); }

    100% {
        transform: rotate(1); } }




@import url('https://fonts.googleapis.com/css?family=Barlow+Condensed:400,500,600|Ubuntu:300,400,500,700&subset=latin-ext');
@import url('https://stackpath.bootstrapcdn.com/bootstrap/4.2.1/css/bootstrap.min.css');

html, body, #root {
    height: 100%;
}

html, body { width:100%;  overflow:hidden; }

#background-video {
    position: fixed;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
}

.main__inner {
    position: absolute!important;
    top: 8px;
    left: 0;
    width: calc(100%)!important;
    height: calc(100% - 16px)!important;
    overflow: auto;
}

.container {
    display: flex;
    flex-flow: column;
    height: 100%;
}

.main-center {
    justify-content: center;
    align-items: center;
}

.color-gray {
    color: rgba(255, 255, 255, 0.6);
}

.mb-35 {
    margin-bottom: 2.1875rem!important;
}

.mb-44 {
    margin-bottom: 2.75rem!important;
}

a {
    color: #19195c;
}

a:hover {
    color: #202693;
}

.w-400 {
    max-width: 25rem;
    margin: 0 auto;
}

.col-6 {
    padding-left: 1rem;
    padding-right: 1rem;
}
.row {
    margin-left: -1rem;
    margin-right: -1rem;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1 a {
    text-decoration: none!important;
    color: #fff;
}

h1 a:hover {
    color: #fff;
}

h1 {
    font-size: 30px;
    font-weight: 700;
    margin: 0;
    color: #fff;
    line-height: 1em;
}

h1 img {
    margin: 7px 0;
}

h2 {
    font-weight: 700;
}

p {
    margin-bottom: 20px;
}

.responsive-img {
    max-width: 100%;
    height: auto
}


.btn-primary {
    background: linear-gradient(to bottom right, #202693, #19195c);
}

.btn-primary:hover, .btn-primary:active, .btn-primary:focus {
    background: linear-gradient(to bottom right, #19195c, #09094c);
    color: #fff;
}

.btn {
    border: none;
    border-radius: 4px;
    transition: all .2s;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.coins {
    margin-right: 20px;
    padding-left: 30px;
}

pre {
    margin-bottom: 0;
    font-size: 100%;
    background: transparent!important;
}

.ReactModal__Overlay {
    z-index: 1001;
}

.ReactModal__Content {
    padding: 0!important;
    max-width: 31.25rem!important;
    margin: 0 auto!important;
    bottom: auto!important;
    height: calc(100% - 5rem);
    display: flex;
    align-items: center;
}

.ReactModal__Content.modal-752 {
    max-width: 47rem!important;
}

.ReactModal__Content.modal-752 .modal-dialog {
    width: 47rem;
}

.ReactModal__Content.modal-752 .modal-content {
    padding: 3.375rem 5.5rem;
}

.ReactModal__Content.modal-752 .modal-footer {
    padding-top: 0;
    margin: 1em -3.5em -1.5rem -3.5rem;
    text-align: right;
}

.ReactModal__Content.modal-608 {
    max-width: 38rem!important;
}

.ReactModal__Content.modal-608 .modal-dialog {
    width: 38rem;
}

.ReactModal__Content.modal-608 .modal-content {
    padding: 3.375rem 1.5rem 1.5rem 3.375rem;
}


.modal-content {
    background: linear-gradient(90.85deg, #1D1E34 8.8%, #181A33 105.31%);
    border: none;
    box-shadow: 0 2px 4px rgba(25, 17, 34, 0.1);
    color: #fff;
    padding: 1.875rem;
    border-radius: 0.625rem;
}

.modal-content .close {
    color: #fff;
    text-shadow: none;
}

.modal-footer, .modal-header {
    border-color: #f5f3f7;
}

.modal-dialog {
    max-width: 100%;
    width: 31.25rem;
    margin: 0 auto;
    max-height: 100%;
}

.modal-header,
.modal-body,
.modal-footer {
    border: 0;
    padding: 0;
}

.modal-footer {
    padding-top: 2.5rem;
}

.modal-title {
    font-weight: bold;
}

.modal-footer {
    display: block;
    text-align: right;
}

.lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}
.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}
@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
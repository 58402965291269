.App-notification {
    margin: rem(5) 0;
    padding: rem(8);
    position: relative;

    &-message2 {
        text-align: right; }

    &-dropdown {
        background: url(../../../../public/assets/images/icon/arrow-dropdown.svg) no-repeat top left / rem(7) rem(12);
        margin-right: rem(4);
        width: rem(7);
        height: rem(12);
        display: inline-block;
        cursor: pointer;
        transition: transform .2s;
        transform-origin: center center; }

    label {
        display: flex;
        justify-content: space-between;
        align-items: center; }

    input {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;

        + label + .on-checked {
            display: none; }

        &:checked {
            + label + .on-checked {
                display: block; }

            + label .App-notification-dropdown {
                transform: rotate(90deg); } } }


    &-left {
        display: flex;
        align-items: center; }

    &-ico {
        width: rem(29);
        height: rem(29);
        background: #282740;
        border-radius: rem(15);
        margin-right: rem(10);
        border: rem(1) #444362 solid;

        &--deposit {
            background: url(../../../../public/assets/images/icon/arrow_upward.svg) #282740 no-repeat center center / rem(17) rem(17); }

        &--withdraw {
            background: url(../../../../public/assets/images/icon/arrow_upward.svg) #282740 no-repeat center center / rem(17) rem(17);
            transform: rotate(180deg); }

        &--info {
            background: url(../../../../public/assets/images/icon/notifications_active.svg) #282740 no-repeat center center / rem(17) rem(17); }

        &--stacking {
            background: url(../../../../public/assets/images/icon/stacking-v2.svg) #282740 no-repeat center center / rem(17) rem(17); }

        &--stardust-today {
            background: url(../../../../public/assets/images/icon/mining.svg) #282740 no-repeat rem(5) rem(4) / rem(17) rem(17); } }

    &-message3 {
        font-size: rem(13);
        line-height: 120%;
        color: rgba(255, 255, 255, 0.6);
        padding-left: rem(52);

        a {
            position: relative;
            color: rgba(255, 255, 255, 0.6);
            text-decoration: none;
            transition: color .2s;

            &:before {
                content: "";
                position: absolute;
                top: rem(-2);
                right: rem(-20);
                width: rem(20);
                height: rem(20);
                background: url(../../../../public/assets/images/icon/external-link.svg) no-repeat center center / rem(20) rem(20); }


            &:hover {
                color: #fff; }

            span {
                margin-right: rem(7); } } } }



.button {
    text-align: center;

    &-close {
        background: url(../../../public/assets/images/icon/close.svg) transparent no-repeat center center / rem(24) rem(25);
        border: none;
        width: rem(24);
        height: rem(25);
        color: transparent;
        cursor: pointer; }

    &[disabled] {
        opacity: .6; }

    &__library {
        padding-left: rem(50)!important;
        background: url(../../../public/assets/images/icon/cup.svg) no-repeat left rem(16) center / rem(24) rem(24); }

    &__teach {
        padding-left: rem(50)!important;
        background: url(../../../public/assets/images/icon/Tabler.svg) no-repeat left rem(16) center / rem(24) rem(24); }

    &--primary {
        background: linear-gradient(91.58deg, #513FF5 1.24%, #FF5E22 65.86%, #FF5E22 97.41%);
        border-radius: rem(10);
        padding: rem(2);
        display: inline-block;
        border: 0;
        position: relative;
        min-width: rem(123);
        cursor: pointer;

        span {
            /*background: #282740*/
            display: inline-block;
            border-radius: rem(8);
            font-weight: 700;
            font-size: rem(16);
            line-height: 120%;
            color: #fff;
            padding: rem(16);
            transition: all .2s;
            position: relative;
            z-index: 2; }

        &:after {
            content: "";
            position: absolute;
            top: rem(2);
            left: rem(2);
            width: calc(100% - 0.25rem);
            height: calc(100% - 0.25rem);
            border-radius: rem(8);
            opacity: 0;
            transition: opacity .2s;
            background: linear-gradient(259.55deg, rgba(255, 94, 34, 0.04) 0.72%, rgba(81, 63, 245, 0.24) 98.18%); }


        &:before {
            content: "";
            position: absolute;
            top: rem(2);
            left: rem(2);
            width: calc(100% - 0.25rem);
            height: calc(100% - 0.25rem);
            border-radius: rem(8);
            opacity: 1;
            transition: opacity .2s;
            background: #1a1c33; }

        &.w-100 span {
            width: 100%; }

        &:focus {
            outline: rem(2) solid rgba(255, 255, 255, 0.6);
            outline-offset: rem(3); }

        &:hover {
            &:after {
                opacity: 1; } } }

    &--secondary {
        background: #444362;
        border-radius: rem(10);
        padding: rem(2);
        display: inline-block;
        border: 0;
        position: relative;
        min-width: rem(123);
        cursor: pointer;

        span {
            /*background: #282740*/
            display: inline-block;
            border-radius: rem(8);
            font-weight: 700;
            font-size: rem(16);
            line-height: 120%;
            color: #fff;
            padding: rem(16);
            transition: all .2s;
            position: relative;
            z-index: 2; }

        &:after {
            content: "";
            position: absolute;
            top: rem(2);
            left: rem(2);
            width: calc(100% - 0.25rem);
            height: calc(100% - 0.25rem);
            border-radius: rem(8);
            opacity: 0;
            transition: opacity .2s;
            background: radial-gradient(92.97% 104.63% at 50% 50%, rgba(49, 47, 78, 0) 0%, #3C3A5B 100%); }


        &:before {
            content: "";
            position: absolute;
            top: rem(2);
            left: rem(2);
            width: calc(100% - 0.25rem);
            height: calc(100% - 0.25rem);
            border-radius: rem(8);
            opacity: 1;
            transition: opacity .2s;
            background: linear-gradient(219.67deg, #1a1c33 15.61%, #34324F 179.87%); }

        &.w-100 span {
            width: 100%; }

        &:focus {
            outline: rem(2) solid rgba(255, 255, 255, 0.6);
            outline-offset: rem(3); }

        &:hover {
            background: linear-gradient(272.67deg, #5E5C8B 9.42%, #3C3A5B 87.22%);

            &:after {
                opacity: 1; } } }



    &--double {
        span {
            line-height: 120%;
            font-size: rem(16);
            font-weight: 700;
            margin-bottom: 2px;
            padding: rem(10) rem(35); } }


    &--client {
        span {
            line-height: 120%;
            font-weight: 400;
            font-size: rem(13);
            padding: rem(14) rem(20);

            strong {
                display: block;
                line-height: 120%;
                font-weight: 700;
                font-size: rem(16); } }

        &--unity {
            span {
                padding: rem(18) rem(32) rem(18) rem(57);
                background: url(../../../public/assets/images/icon/neos_steam.svg) no-repeat rem(17) center / rem(24) rem(24); } }


        &--unreal {
            span {
                padding: rem(18) rem(16) rem(18) rem(57);
                background: url(../../../public/assets/images/icon/neos_unreal.svg) no-repeat rem(19) center / rem(32) rem(32); } } }
    &__desc {
        font-size: rem(12);
        font-style: normal;
        font-weight: 500;
        line-height: rem(40);
        letter-spacing: rem(1); }

    &--double + &--double {
        margin-left: rem(32); } }

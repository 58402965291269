.OAuth2 {
    &__continue {
        text-align: center;

        img {
            border-radius: 100%;
            width: 32px;
            height: 32px;
            margin-bottom: 10px; }

        &__name {
            font-weight: bold; }

        .button {
            margin-bottom: 10px; } } }


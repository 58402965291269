@function rem($value) {
    $remValue: ($value / 16) + rem;
    @return $remValue; }


@import "components/button";
@import "components/link";
@import "components/app/notification";

@import "pages/App";

html {
    font-size: 16px;

    @media screen and (max-width: 1500px) {
        font-size: 14px; }

    @media screen and (max-width: 1370px) {
        font-size: 12px; }

    @media screen and (max-width: 1060px) {
        font-size: 9px; }

    @media screen and (max-width: 895px) {
        font-size: 8px; }

    @media screen and (max-width: 800px) {
        font-size: 7px; }

    @media screen and (max-width: 715px) {
        font-size: 6px; }

    @media screen and (max-width: 630px) {
        font-size: 5px; }

    @media screen and (max-width: 540px) {
        font-size: 4px; }

    @media screen and (max-width: 440px) {
        font-size: 4px; } }


#root {
    max-width: rem(1294);
    margin: 0 auto;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center; }


body {
    margin: 0;
    padding: rem(60);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: #151826;
    font-family: 'Lato', sans-serif;
    background: url(../../public/assets/images/background.png) no-repeat center center / cover; }


.mb-12 {
    margin-bottom: rem(12); }

.h100 {
    height: 100%;

    &.App-box {
        height: calc(100% - 0.5rem); } }

main {
    display: flex;
    justify-content: flex-start;
    align-items: start;
    padding: rem(8) rem(8);
    position: relative;
    overflow: hidden;
    flex: 1 1 auto;
    width: 100%; }

.main__inner {
    position: absolute!important;
    top: rem(8);
    left: 0;
    width: calc(100%)!important;
    height: calc(100% - 1rem)!important;
    overflow: auto; }

p {
    margin-bottom: rem(20); }

.container {
    padding: 0 rem(7);
    max-width: 100% !important; }

@media screen and (min-width: 1415px) {
    .Home {
        overflow: hidden; } }

.link {
    color: #fff;
    text-decoration: none;

    &:hover {
        color: #fff; }

    &.is-disable {
        opacity: 0.3;
        cursor: not-allowed; }

    &--next {
        padding-right: rem(28);
        padding-bottom: rem(2);
        background: url(../../../public/assets/images/icon/arrow_forward.svg) no-repeat right center / rem(24) rem(24); } }
